import { startStimulusApp } from '@symfony/stimulus-bridge'

// Import 3rd party controllers

// Registers Stimulus controllers from controllers.json and in the controllers/ directory
export const app = startStimulusApp(require.context(
  '@symfony/stimulus-bridge/lazy-controller-loader!./controllers',
  true,
  /\.(j|t)sx?$/
))

// Enable Stimulus debug mode in development
app.debug = process.env.NODE_ENV === 'development';
