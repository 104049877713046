import {Controller} from "@hotwired/stimulus"

export default class extends Controller {

  inputs = []

  static values = {
    sportoffer: String,
    sportgruppe: String,
    zertRehasportgruppe: String
  }

  static targets = ['sportoffer', 'sportgruppe', 'zertRehasportgruppe']

  selectChanged(e) {
    const valueChangedName = e.currentTarget.dataset.name
    const value = e.currentTarget.value

    switch (valueChangedName) {
      case 'sportoffer':
        this.sportofferValue = value
        break
      case 'sportgruppe':
        this.sportgruppeValue = value
        break
      case 'zertRehasportgruppe':
        this.zertRehasportgruppeValue = value
        break
    }
  }

  sportofferValueChanged() {
    if (this.sportofferValue) {
      this.zertRehasportgruppeTarget.value = ''
    }
  }

  sportgruppeValueChanged() {
    if (this.sportgruppeTarget.value) {
      this.sportofferTarget.setAttribute('required', 'required')
      this.zertRehasportgruppeTarget.value = ''
    }
  }

  zertRehasportgruppeValueChanged() {
    console.log("''': " + this.zertRehasportgruppeValue)
    if (this.zertRehasportgruppeValue) {
      this.sportofferTarget.value = ''
      this.sportgruppeTarget.value = ''

      this.sportofferTarget.removeAttribute('required')
    }
  }
}
